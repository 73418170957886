import axios from "axios";
import SocketIO from "socket.io";
// let config = require("../config");
import { report_url, report_endPoints } from "../config";

// REASON for commenting: new callhistory document is being created at time of verify call
// let getInitiationId =  (socket,callId) => {
//     try {
//         socket.emit("createReport",{ "callId": `${callId}`});

//         //removed api hits and converted to socket
//         // const initiationId = await axios.post(`${report_url}${report_endPoints.new}`, { "callId": `${callId}` })
//         // return initiationId.data.initiationId
//     }
//     catch (err) {
//         console.log(err);
//     }

// }

let logReportData =  (socket,initiationId,data)=>  {
    try {
        console.log("in functions")
            let body = {};
            body.initiationId = initiationId;
            body.formData = data;
            console.log(body);
        
            //end user will provide update via socket to scheduling
            socket.emit("updateReport",body, (data)=>{
                //This is a custom event that is triggered after Landing page is reported to MIS
                if(body.formData.onPage && body.formData.onPage == "Landing" && data == true) {
                    eventBus.$emit("landing-page-mis-triggered");
                }
                //This is a custom event that is triggered after Prerequisite check is passed and reported to MIS
                if(body.formData.currentProcess && body.formData.currentProcess ==  "geo location fetched passed" && data == true){
                    eventBus.$emit("completed-prerequisites-check");
                }
            });
    }
    catch (err) {
        console.log(err);
    }
}

export {
    logReportData
}